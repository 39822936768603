<template>
  <div class="col-md-12 mx-auto">
    <div class="card rounded shadow-lg">
      <div class="card-body">
        <div class="card-title text-center">
          <h1>Correo electrónico conectado</h1>
        </div>
        <br>
        <div class="row">
          <div class="col-md-12">
            <div class="form-group row">
              <form v-on:submit.prevent="searchItems({ key: keywords })">
                <div class="input-group">
                  <input type="text" v-model="keywords" class="form-control" placeholder=""
                    aria-label="Buscar por palabras claves" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button type="submit" class="btn btn-outline-primary">Buscar</button>
                    <!--
                      <button
                        type="button"
                        v-on:click="newSearch()"
                        class="btn btn-primary"
                        data-toggle="modal"
                        data-target="#searchModal"
                      >Búsqueda avanzada</button>                
                      <search-inbox-user v-on:search="searchItems" v-bind:item="search"></search-inbox-user>
                      -->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="">
              <button v-on:click="$router.push({ name: 'CreateInboxUser', params: { add: true, search: search } });"
                class="btn btn-primary"><i data-feather="plus-circle"></i>Conectar correo electrónico</button>
            </div>
            <br>
            <div v-if="filter" class="alert alert-primary" role="alert">
              {{ keywords }}
              <v-icon v-on:click="closeSearch">mdi-filter-remove</v-icon>
            </div>
            <div class="tab-content" id="nav-tabContent">
              <div class="table-responsive">
                <table class="table table-hover table-bordered">
                  <thead>
                    <tr>
                      <td class="text-center"><input type="checkbox" v-model="allChecked" v-on:click="checkAllItems"></td>
                      <td>Correo electrónico</td>
                      <td>Predeterminar</td>
                      <td>Estado identidad</td>
                      <td>Estado</td>
                      <td></td>
                    </tr>
                  </thead>

                  <tbody>
                    <tr v-for="(item, index) in items" :key="index">
                      <td class="text-center">
                        <input type="checkbox" v-bind:value="item._id" v-model="delItems">
                      </td>
                      <td>{{ item.inb_user }}</td>
                      <td>
                        <a v-if="!item.inb_default" href="#" v-on:click.prevent="showDefaultModal(item)">Establecer como
                          predeterminado</a>
                        <p v-else class="font-weight-bold text-primary">(Predeterminado)</p>
                      </td>
                      <td>
                        <span>{{ statusIdentity(item.identity_verified) }}</span>
                      </td>
                      <td>
                        <span v-if="item.openedInbox">Abierto</span>
                        <span v-else>Cerrado</span>
                      </td>
                      <td class="d-flex justify-content-center">
                        <button class="btn v-btn--outlined d-flex border-0" style="align-items: center;" type="button"
                          title="Verificar identidad" @click="verifyIdentity(item)">
                          <img
                            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAAAyVBMVEUjLz4gLTwSIjQOIDIZJzgYJjdGTlmTl5ykp6tpb3cAGS45Qk5gZ3AyPEkqNUOKjpRaYWv////c3d9SWWMAFSu0trqanqP09fbS09V1eoIAABkAAACoq7AAAA7k5ebFx8qBhYsAABwACiV+g4pBSlW4u78AABQAACEAFyy2uLwdLT8ACS4AJkATKj9gSTUXKz9LPzi5dSDjihOOYCp4VTDpjgpmTDJWRTXIfRvTgxZCOzn7lwCrbiUAI0AvNDuZZSeIXS3/oQBwUTHffSETAAABKUlEQVR4AeyOhWHAIBBFIQrE+Ffi7rL/fnVhiD7kXNg/NtxxPcY99v2+bAs/CIVUUezx5P2l0mOZkFaGp0EEIfFicsSqgFeWFWorI/WatkOPohkgmmqc0M8LZ3+4QRF2aKtKoaAG64Yh4461Qo1qH3BM0OWGANzVIyD93wRD1PYF5hTjfmIk46vlGMj8JqgROgOChTDNFbJrFVriVr8JPCHQNm7uWiXO+/M0AbvLfnm4McZTnPnX1/OMeSuHKgwgBmEgv056wanh7L/Tu6/QQ+I6TeC3XUKJX5A21rqXh/LBA/TtoOS8rBteIu8hJp3BShGRYuTiQjWVPxlziz2utUmtl+FM6NHnKL53yfBbfyGuA7yPv8YUIF0yJrUFt6ZP1/cPxQwwkzgeLn8iJrwGC2jyAAAAAElFTkSuQmCC"
                            alt="" height="110%">
                        </button>

                        <button v-on:click="editItem(item._id)" class="btn btn-primary">Editar</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <br>
            <div>
              <button class="btn btn-danger" v-on:click="deleteItems" v-bind:disabled="!checkedItems">
                <i data-feather="delete"></i>Eliminar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div class="modal" id="defaultModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
      aria-hidden="true">
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Confirmar</h5>
          </div>
          <div class="modal-body">
            <div style="text-align:center"><img src="/images/other_mail.png"></div>
            <div style="text-align:center">
              <p>¿Desea establecer esta cuenta de correo como predeterminada?</p>
            </div>
            <div style="text-align:center">
              <p class="font-weight-bold text-primary">{{ defaultEmailObject.inb_user }}</p>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6 text-center">
                <button type="button" class="btn btn-primary"
                  v-on:click.prevent="setDefaultEmail(defaultEmailObject._id)">Aceptar</button>
              </div>
              <div class="form-group col-md-6 text-center">
                <button type="button" class="btn btn-secondary" v-on:click.prevent="cancelDefaultEmail">Cancelar</button>
              </div>
            </div>
          </div>
          <div class="modal-footer">

          </div>
        </div>
      </div>
    </div>
    <!-- End Modal -->

    <v-dialog v-model="modalConfirm.estado" scrollable width="auto">
      <v-card>
        <v-card-title>{{ modalConfirm.message }}</v-card-title>
        <v-divider class="my-1"></v-divider>
        <v-card-actions class="w-100 d-flex justify-content-end">
          <v-btn class="green darken-1 white--text" @click="modalConfirm.estado = false">
            Aceptar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <ModalForm :visible="loader.estado" @close="loader.estado = false" :message="loader.message"></ModalForm>
  </div>
</template>

<script>
//import { VIcon } from 'vuetify/lib';
import SearchInboxUser from "./SearchInboxUser.vue";
import { formatBoolean, formatDateUtc } from '../../plugins/filters';
import ModalForm from "../modal/ModalForm.vue";

export default {
  components: {
    //VIcon,
    SearchInboxUser,
    ModalForm
  },
  data() {
    return {
      items: [],
      delItems: [], //List of items to delete
      allChecked: false, //Check all items
      keywords: '',
      search: {},
      filter: false, // Items are filtered
      defaultEmailObject: {},
      loader: {
        estado: false,
        message: "",
      },
      modalConfirm: {
        estado: false,
        message: null
      }
    }
  },
  filters: {
    formatBoolean,
    formatDateUtc
  },
  computed: {
    checkedItems() {
      if (this.delItems.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  },
  mounted: function () {
    feather.replace();
  },
  created: function () {
    if (this.$route.params.search) {
      this.search = this.$route.params.search;
      this.searchItems(this.search);
    } else {
      this.searchItems();
    }
  },
  methods: {
    deleteItem(id, index) {
      const response = confirm('Está seguro que desea eliminar?');
      if (response) {
        let uri = '/inboxes/delete/' + id;
        this.axios.get(uri)
          .then(res => {
            this.items.splice(index, 1);
          })
          .catch(err => console.log(err));
      }
      return;
    },
    deleteItems() {
      if (this.delItems.length > 0) {
        const response = confirm('Está seguro que desea eliminar los registros seleccionados?');
        if (response) {
          this.show = true;
          let uri = '/inboxes/deleteitems';
          this.axios.post(uri, { delItems: this.delItems })
            .then(res => {
              this.show = false;
              if (this.filter) {
                this.searchItems(this.search);
              } else {
                this.searchItems();
              }
            })
            .catch(err => {
              this.show = false;
              alert(err.response.data.msg);
              if (err.response.data.i > 0) {
                if (this.filter) {
                  this.searchItems(this.search);
                } else {
                  this.searchItems();
                }
              }
            });
        }
      }
      return;
    },
    checkAllItems() {
      this.delItems = [];
      if (!this.allChecked) {
        for (var i = 0; i < this.items.length; i++) {
          this.delItems.push(this.items[i]._id);
        }
      }
    },
    searchItems(p) {
      if (p != null) {
        if (p.key) {
          this.keywords = p.key;
        } else {
          this.keywords = '';
        }
      } else {
        p = {};
      }

      this.message = 'Buscando buzones';
      p.cmp_id = this.$store.state.company;
      p.inb_owner_id = this.$store.state.user;
      let uri = '/inboxes/search';
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
        .then(async (response) => {
          var list = response.data;

          let index = 0
          for (const item of list) {
            const stateinbox = await this.getStateinbox(item, index)

            if (stateinbox) {
              var state = stateinbox.state;

              if (state == 'disconnected') {
                list[index].openedInbox = false;
                list[index].disableButton = false;
              } else {
                list[index].openedInbox = true;
                list[index].disableButton = false;
              }
            }

            const statusIdentity = await this.getStatusIndentity(item)

            if (statusIdentity) {
              list[index].identity_verified = statusIdentity.verification
            }

            index++
          }

          this.items = list;
          this.search = p;
          this.delItems = [];
          this.filter = true;
        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los buzones' + err;
        });
    },
    closeSearch() {
      this.filter = false;
      this.search = {};
      this.keywords = '';
      this.items = [];
      this.searchItems();
    },
    newSearch() {
      this.search = {};
    },
    createItem() {
      this.$router.push({ name: 'CreateInboxUser', params: { add: true, search: this.search } });
    },
    editItem(id) {
      this.$router.push({ name: 'CreateInboxUser', params: { id: id, add: false, search: this.search } });
    },
    showDefaultModal(obj) {
      this.defaultEmailObject = { inb_user: obj.inb_user, _id: obj._id };
      $('#defaultModal').modal('show');
    },
    setDefaultEmail(id) {
      var p = {};
      let uri = '/inboxes/default';
      p.cmp_id = this.$store.state.company;
      p.owner_id = this.$store.state.user;
      p.id = id;
      this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
      this.axios.post(uri, p)
        .then(response => {
          $('#defaultModal').modal('hide');
          if (this.filter) {
            this.searchItems(this.search);
          } else {
            this.searchItems();
          }
        })
        .catch(err => {
          $('#defaultModal').modal('hide');
          if (this.filter) {
            this.searchItems(this.search);
          } else {
            this.searchItems();
          }
        });
    },

    async getStateinbox(item, index) {
      try {
        let uri = '/siged-email/api/stateinbox';
        const response = await this.axios.post(uri, { inb_id: item._id, index })

        return response.data
      } catch (error) {
        return null
      }
    },

    async getStatusIndentity(item) {
      try {
        let uri = '/inboxes/identity/status'
        const response = await this.axios.get(uri, { params: { id: item._id } })

        return response.data
      } catch (error) {
        return null
      }
    },

    async verifyIdentity(item) {
      try {
        if (item.identity_verified == "Success") {
          this.modalConfirm.estado = true
          this.modalConfirm.message = "Esta identidad ya esta conectada"
          return true
        }

        if (item.identity_verified == "Pending") {
          this.modalConfirm.estado = true
          this.modalConfirm.message = "La identidad está pendiente, compruebe la bandeja de entrada o la dirección de correo electrónico"
          return true
        }

        this.loader.estado = true;
        this.loader.message = "Verificando identidad";

        let uri = '/inboxes/identity/verify';
        await this.axios.post(uri, { id: item._id })
        this.loader.estado = false;

        this.modalConfirm.estado = true
        this.modalConfirm.message = "Compruebe la dirección de correo electrónico y haga clic en el primer enlace para confirmar la conexión."

        this.searchItems(this.search);
      } catch (error) {
        console.log("Error: ", error)
        this.loader.estado = false;
      }
    },

    statusIdentity(key) {
      if (!key) return 'No conectada'

      if (key == 'Pending') return 'Pendiente'

      if (key == 'Failed') return 'Fallida'

      if (key == 'Success') return 'Conectada'

      return 'No definida'
    },
    cancelDefaultEmail() {
      $('#defaultModal').modal('hide');
    }
  }
}
</script>

<style>
.inactivo {
  /*
  background-color: #FFEAE8;
  */
  color: lightcoral;
  text-decoration: line-through;
}
</style>