import moment from 'moment';
let localeData = moment.updateLocale('es', { 
  monthsShort: 
      ["ene", "feb", "mar", "abr", "may", 
          "jun", "jul", "ago", "sep", "oct", 
          "nov", "dic"]
});
moment.locale('es');

export function formatDate(value) {
  if (value) {
    return moment(String(value)).format('DD/MMM/YYYY hh:mm A');
  }
}

export function formatDateTime(value) {
  if (value) {
    return moment(String(value)).format('DD/MMM/YYYY hh:mm:ss A');
  }
}

export function formatDateUtc(value) {
  if (value) {
    return moment.utc(String(value)).format('DD/MMM/YYYY');
  }
}

export function formatBoolean(value) {
  if (value) {
    return 'Si';
  }
  return 'No';
}

export function formatTime(value) {
  if (value) {
    return moment(String(value)).format('hh:mm A');
  }
}

export function formatMsToHour(value) {
  if (value) {
    let xh = value/1000/60/60;
    let h = Math.trunc(xh);
    let xm = (xh - h)*60;
    let m = Math.trunc(xm);
    let xs = (xm - m)*60
    let s = Math.trunc(xs);
    return h.toString() + ':' + m.toString() + ':' + s.toString();
  }
}
